<!-- =========================================================================================
    File Name: Floor3dScene.vue
    Description: Page to change the placement xyz position in space
========================================================================================== -->

<template>
  <div>
  <!-- <div class="annotation">
      <p><strong>Cube</strong></p>
      <p>In geometry, a cube is a three-dimensional solid object bounded by six square faces, facets or sides, with three meeting at each vertex.</p>
  </div> -->
  <div id="scene3d">
      <img
        id="iPhoneUi"
        v-if="isFirstPersonView"
        :src="require('../../../../../assets/images/hoverlay/iPhone-Ui.png')"
        :height="CANVAS_HEIGHT - 50"
        :width="CANVAS_HEIGHT / 2"
      />
      <!-- 1st-Person View Switch  -->
      <center>
        <div class="povBottomRight">
          <vx-tooltip
            color="#7c53e6"
            text="Simulate what users would see when viewing this space through a typical smartphone in the Hoverlay app."
          >
            First-Person View
          </vx-tooltip>
          <vs-switch v-model="isFirstPersonView">
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
        </div>
      </center>
      <center v-if="controlModeFloor" class="mt-2 center-top-left">
        <ul>
          <li>
            <vs-radio v-model="controlModeFloor" vs-value="translate">Move</vs-radio>
          </li>
          <li>
            <vs-radio v-model="controlModeFloor" vs-value="rotate">Rotate</vs-radio>
          </li>
          <li>
            <vs-radio v-model="controlModeFloor" vs-value="scale">Scale</vs-radio>
          </li>
        </ul>
      </center>
      <!-- <div v-if="this.renderer">{{ this.renderer.info }}</div> -->
      <div v-if="isLoading" class="center-top-right-loading">
        <div class="contained-example-container">
          <div id="div-with-loading" class="vs-con-loading__container"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { Grid } from '@pmndrs/vanilla'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js'
import * as TWEEN from '@tweenjs/tween.js'
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";

import { OutlinePass2 } from "@/assets/js/utils/OutlinePass2.js";

import * as HoverlayThreeUtils from '@/assets/js/utils/hoverlay-three-utils.js'
import * as Command from '@/assets/js/utils/commands.js'
import { ObjectSelector } from '@/assets/js/utils/object-selector.js'
import { DistanceHelper } from '@/assets/js/utils/distance-helper.js'

import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import * as DestroyUtils from '@/assets/js/utils/destroy-utils.js'
import { YARDS_TO_METERS, METERS_TO_YARDS, INCHES_TO_METERS } from '@/assets/js/utils/unit-converter.js' 

import 'video.js/dist/video-js.css'
import 'flatpickr/dist/flatpickr.css'
import { Model } from 'vue-property-decorator'

export default {
  components: {},
  props: {
    selectedNodes: [],
    space: {},
    nodes: {},
    anchors: {},
  },
  computed: {
    selectedNodesCopy() {
      return [...this.selectedNodes];
    },
  },
  data() {
    return {
      // Three js variables
      cameraPersp: null,
      currentCamera: null,
      scene: null,
      meshes: [],
      bboxes: [],
      renderer: null,
      controlModeFloor: null,
      transformDictionnary: [],
      mixerDictionnary: [],
      transformControls: null,
      orbit: null,
      CANVAS_HEIGHT: null,
      CANVAS_WIDTH: null,
      anchorObject: null,
      mannequinObject: null,
      isFirstPersonView: false,
      thirdPersonCoordinates: null,
      thirdPersonOrbitTarget: null,
      thirdPersonAngleX: null,
      thirdPersonAngleY: null,
      thirdPersonAngleZ: null,
      // Raycasting
      raycaster: null,
      mouse: null,
      threeJSVideoComponents: [],
      clock: null,
      requestAnimationFrameId: null,
      dracoLoader: null,
      gltfLoader: null,
      isLoading: true,
      // Thumbnail
      captureThumbnail: false,
      thumbnail: null,
      objectSelector: null,
      annotation: null,
    }
  },
  watch: {
    //swaps between first-person view and third-person view
    isFirstPersonView: function () {
      //if switch is ON
      if (this.isFirstPersonView) {
        //go into 3rd Person view
        //copy data of 3rd-Person Camera for else case
        this.thirdPersonCoordinates = JSON.parse(JSON.stringify(this.currentCamera.position))
        this.thirdPersonAngleX = JSON.parse(JSON.stringify(this.currentCamera.rotation.x))
        this.thirdPersonAngleY = JSON.parse(JSON.stringify(this.currentCamera.rotation.y))
        this.thirdPersonAngleZ = JSON.parse(JSON.stringify(this.currentCamera.rotation.z))
        this.thirdPersonOrbitTarget = JSON.parse(JSON.stringify(this.orbit.target))

        const coords = {
          x: this.currentCamera.position.x,
          y: this.currentCamera.position.y,
          z: this.currentCamera.position.z,
        }
        const angles = {
          x: this.currentCamera.rotation.x,
          y: this.currentCamera.rotation.y,
          z: this.currentCamera.rotation.z,
        }

        //animation for camera position
        new TWEEN.Tween(coords)
          .to({ x: 0, y: 1.3, z: 0 }, 750)
          .easing(TWEEN.Easing.Back.In)
          .onUpdate(() => this.currentCamera.position.set(coords.x, coords.y, coords.z))
          .start()

        //animation for camera angle
        new TWEEN.Tween(angles)
          .to({ x: 0, y: 0, z: 0 }, 750)
          .onUpdate(() => this.currentCamera.rotation.set(angles.x, angles.y, angles.z))
          .start()

        //setup for 1st-Person View
        this.orbit.minDistance = 0
        this.orbit.enableZoom = false
        this.orbit.enablePan = false
        this.orbit.maxDistance = 0.01
        this.orbit.maxPolarAngle = Math.PI
        this.orbit.target = new THREE.Vector3(0, 1.3, -0.01) //position that camera is centered at
        this.mannequinObject.traverse(function (object) {
          object.visible = false
        }, 700) //should delay when mannequin disappears but doesn't work,
        this.currentCamera.fov = 75
        this.currentCamera.updateProjectionMatrix()
      }
      //if switch is OFF
      else {
        //go into 3rd-Person View

        //setup for tween
        const coords = {
          x: this.currentCamera.position.x,
          y: this.currentCamera.position.y,
          z: this.currentCamera.position.z,
        }
        const angles = {
          x: this.currentCamera.rotation.x,
          y: this.currentCamera.rotation.y,
          z: this.currentCamera.rotation.z,
        }

        //animation for camera position
        new TWEEN.Tween(coords)
          .to(
            { x: this.thirdPersonCoordinates.x, y: this.thirdPersonCoordinates.y, z: this.thirdPersonCoordinates.z },
            750
          )
          .easing(TWEEN.Easing.Back.In)
          .onUpdate(() => this.currentCamera.position.set(coords.x, coords.y, coords.z))
          .start()

        //animation for camera angle
        new TWEEN.Tween(angles)
          .to({ x: this.thirdPersonAngleX, y: this.thirdPersonAngleY, z: this.thirdPersonAngleZ }, 750)
          .onUpdate(() => this.currentCamera.rotation.set(angles.x, angles.y, angles.z))
          .start()

        //Set-up for going back to 3rd-Person View
        this.orbit.maxPolarAngle = Math.PI / 2 // prevent the camera from going under the ground
        this.orbit.minDistance = 1 // the minimum distance the camera must have from center
        this.orbit.maxDistance = 100 // the maximum distance the camera must have from center
        this.orbit.enableZoom = true
        this.orbit.enablePan = true
        this.orbit.target = new THREE.Vector3(
          this.thirdPersonOrbitTarget.x,
          this.thirdPersonOrbitTarget.y,
          this.thirdPersonOrbitTarget.z
        )
        setTimeout(
          this.mannequinObject.traverse(function (object) {
            object.visible = true
          }),
          700
        )
        this.currentCamera.fov = 50
        this.currentCamera.updateProjectionMatrix()
      }
    },
    controlModeFloor: function (mode) {
      this.objectSelector.changeTransformControlMode(mode)
    },
    selectedNodesCopy(newNodes, oldNodes) {
      // Update the selected 3D objects in the Three.js scene
      this.updateSelectedObjects(newNodes);
    },
    nodes: function (newNodes, oldNodes) {

      // Indentify nodes that have been ADDED and add them to the scene
      var onlynewNewNodes = newNodes.filter(HoverlayUtils.spacesComparer(oldNodes))

      for (const node of onlynewNewNodes) {
        this.createHobjectRepresentation(node)
      }

      // Indentify nodes that have been REMOVED and remove them from the threeJS scene
      // Detach transform first and then remove object from the scene
      var onlyInOldNodes = oldNodes.filter(HoverlayUtils.spacesComparer(newNodes))

      onlyInOldNodes.forEach(node => {

        if (this.objectSelector.isSelected(node.pid))
          this.objectSelector.remove(node)

        if (this.transformDictionnary[node.pid].parent)
          this.transformDictionnary[node.pid].parent.remove(this.transformDictionnary[node.pid])
        else this.scene.remove(this.transformDictionnary[node.pid])
        this.meshes = this.meshes.filter(mesh => mesh.name !== node.pid)
        delete this.mixerDictionnary[node.pid]
        DestroyUtils.disposeHierarchy(
          {
            node: this.transformDictionnary[node.pid],
          },
          DestroyUtils.disposeNode
        )
        this.transformDictionnary[node.pid] = null
        delete this.transformDictionnary[node.pid]
        var selectedObject = this.scene.getObjectByName(node.pid)
        this.scene.remove(selectedObject)

        this.render()
        
      })
    },
  },
  // https://sketchfab.com/3d-models/wooden-artist-mannequin-be47b21c2e8f4a0f975e89a9c50a4aa5
  mounted() {
    this.$vs.loading({
      container: '#div-with-loading',
      scale: 0.6,
    })

    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()
    this.clock = new THREE.Clock()

    // Adjust 3D scene height
    var win = window,
      doc = document,
      docElem = doc.documentElement,
      body = doc.getElementsByTagName('body')[0],
      // x = win.innerWidth || docElem.clientWidth || body.clientWidth,
      y = win.innerHeight || docElem.clientHeight || body.clientHeight
    // document.getElementById('scene3d').style.height = `${y * (72 / 100)}px`

    document.getElementById('scene3d').style.height = `${y * (72 / 100)}px`

    // Optional: Provide a DRACOLoader instance to decode compressed mesh data
    this.gltfLoader = new GLTFLoader()
    this.dracoLoader = new DRACOLoader()
    this.dracoLoader.setDecoderPath(`${window.location.origin}/js/draco/`)
    this.gltfLoader.setDRACOLoader(this.dracoLoader)

    this.plyLoader = new PLYLoader()

    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.setupScene()

      this.addMannequin()
      if (this.space.settings && this.space.settings.compass_oriented) this.addCompassImage()

      this.render()
      this.controlModeFloor = 'translate'
    })

    this.$eventBus.$on('nodeTransformChanged', this.onNodeTransformChanged)
    this.$eventBus.$on('onSpaceSaved', this.onSpaceSaved)
  },
  beforeDestroy() {
    this.$eventBus.$off('nodeTransformChanged')
    this.$eventBus.$off('onSpaceSaved')

    this.disposeScene()

    //    for (const video of this.threeJSVideoComponents) {
    //   video = null;
    // }
  },
  methods: {

    // Update the selected 3D objects in the Three.js scene

    onNodeTransformChanged(node) {
      var transform = this.transformDictionnary[node.pid]

      if (transform == null) return

      // change position if needed
      if (transform.position.x != node.x) transform.position.x = node.x
      if (transform.position.y != node.y) transform.position.y = node.y
      if (transform.position.z != -node.z) transform.position.z = -node.z
      // change scale if needed
      if (transform.scale.x != node.x || transform.scale.y != node.y || transform.scale.z != node.z) {
        transform.scale.z = node.scale
        transform.scale.x = node.scale
        transform.scale.y = node.scale
      }

      // If transform world quaternion rotation is different from node rotation, update the transform
      var worldQuaternion = new THREE.Quaternion();
      transform.getWorldQuaternion(worldQuaternion);
      if (worldQuaternion.x != node.quaternion_x ||
        worldQuaternion.y != node.quaternion_y ||
        worldQuaternion.z != -node.quaternion_z ||
        worldQuaternion.w != -node.quaternion_w) {

        var quaternion = new THREE.Quaternion(
          node.quaternion_x,
          node.quaternion_y,
          -node.quaternion_z,
          -node.quaternion_w
        )

        transform.quaternion.copy(quaternion) // Apply Quaternion
        transform.quaternion.normalize() // Normalize Quaternion
      }

      this.objectSelector.updateGizmos()

    },
    onSpaceSaved() {
      this.captureThumbnail = true
    },
    async setupScene() {
      var scene3d = document.getElementById('scene3d')

      // if (scene3d.firstChild) {
      //   scene3d.firstChild.remove()
      // }

      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true })
      this.renderer.shadowMap.enabled = true
      this.renderer.setPixelRatio(window.devicePixelRatio)

      this.CANVAS_HEIGHT = document.getElementById('scene3d').offsetHeight
      this.CANVAS_WIDTH = document.getElementById('scene3d').offsetWidth

      this.renderer.setSize(this.CANVAS_WIDTH, this.CANVAS_HEIGHT)
      scene3d.appendChild(this.renderer.domElement)

      const aspect = this.CANVAS_WIDTH / this.CANVAS_HEIGHT

      this.cameraPersp = new THREE.PerspectiveCamera(50, aspect, 0.01, 30000)
      this.currentCamera = this.cameraPersp
      this.currentCamera.position.set(-2, 4, 6)

      this.scene = new THREE.Scene()

      this.composer = new EffectComposer(this.renderer);
      const renderPass = new RenderPass(this.scene, this.currentCamera);

      this.composer.addPass(renderPass);

      this.outline = new OutlinePass2(new THREE.Vector2(this.CANVAS_WIDTH, this.CANVAS_HEIGHT), this.scene, this.currentCamera);
      this.outline.edgeThickness = 10;
      this.outline.edgeStrength = 5;
      this.outline.edgeGlow = 2;
      this.outline.usePatternTexture = false;
      // this.outline.visibleEdgeColor.set(0x7367F0);
      // this.outline.hiddenEdgeColor.set(0x7367F0);
      // this.outline.visibleEdgeColor.set(0x006FC5);
      // this.outline.hiddenEdgeColor.set(0x006FC5);
      this.outline.visibleEdgeColor.set(0xf7c100);
      this.outline.hiddenEdgeColor.set(0xf7c100);
      
      this.composer.addPass(this.outline);

        this.fxaaShader = new ShaderPass(FXAAShader);
        const pixelRatio = this.renderer.getPixelRatio();
        this.fxaaShader.uniforms["resolution"].value.set(1 / (this.CANVAS_WIDTH * pixelRatio), 1 / (this.CANVAS_HEIGHT * pixelRatio));
        this.composer.addPass(this.fxaaShader);

      // Uncomment below to use fog. The Grid currently supports fading away, so fog is not needed
      // const color = 0xffffff
      // const density = 0.005
      // this.scene.fog = new THREE.FogExp2(color, density)

      // Uncomment to use default gridHelper
      // var gridHelper = new THREE.GridHelper(1000, 1000, 0x878787, 0xcdcdcd)
      // gridHelper = gridHelper.material.transparent = true
      // gridHelper.material.opacity = 0.9

      // this.scene.add(gridHelper)

      const grid = Grid({
        args: [10.5, 10.5],
        cellSize: this.$store.state.AppActiveUser.measurement == 'metric' ? 1 : YARDS_TO_METERS,  // 1 meter if metric. 1 yard if imperial,
        cellThickness: 1,
        cellColor: new THREE.Color('#878787'),
        sectionSize: this.$store.state.AppActiveUser.measurement == 'metric' ? 5 : 5 * YARDS_TO_METERS,  // 1 meter if metric. 1 yard if imperial,
        sectionThickness: 2.3,
        sectionColor: new THREE.Color('#cdcdcd'),
        fadeDistance: 50,
        fadeStrength: 4,
        followCamera: false,
        infiniteGrid: true,
      })

      this.scene.add(grid.mesh)

      const geometry = new THREE.PlaneGeometry(10000, 1000, 32)
      const material = new THREE.ShadowMaterial({ opacity: 0.2 })
      const plane = new THREE.Mesh(geometry, material)
      
      plane.receiveShadow = true

      plane.rotateX(-Math.PI / 2)

      this.scene.add(plane)

      // Uncomment below to use axesHelper. 
      // const axesHelper = new THREE.AxesHelper(100)
      // axesHelper.size = 3
      // axesHelper.setColors(new THREE.Color(0x007500), new THREE.Color(0xff0000), new THREE.Color(0x0000ff))
      //this.scene.add(axesHelper)

      // Hoverlay custom object selector
      this.objectSelector = new ObjectSelector(this.scene, this.currentCamera, this.renderer.domElement, this.$store.state.AppActiveUser.measurement, false, true);
      this.objectSelector.addEventListener('interaction-started', event => this.interactionStarted(event))
      this.objectSelector.addEventListener('interaction-updated', event => this.interactionUpdated(event))
      this.objectSelector.addEventListener('interaction-ended', event => this.interactionEnded(event))
      this.objectSelector.addEventListener('dragging-changed', event => this.onDraggingChanged(event))
      this.interacting = false

      this.distanceHelper = new DistanceHelper(
        this.scene,
        this.currentCamera,
        this.renderer.domElement,
        this.$store.state.AppActiveUser.measurement,
        true
      )

      this.annotation = document.querySelector(".annotation");

      const light = new THREE.AmbientLight(0x858585) // soft white light
      this.scene.add(light)

      const hemiLight = new THREE.HemisphereLight(0xcccccc, 0x111111)
      hemiLight.position.set(0, 20, -10)
      this.scene.add(hemiLight)

      const dirLight = new THREE.DirectionalLight(0xcccccc)
      dirLight.position.set(-5, 20, +10)
      dirLight.castShadow = true
      dirLight.shadow.camera.top = 100
      dirLight.shadow.camera.bottom = -100
      dirLight.shadow.camera.left = -100
      dirLight.shadow.camera.right = 100
      dirLight.shadow.camera.near = 0.1
      dirLight.shadow.camera.far = 500
      dirLight.shadow.mapSize.width = 5120
      dirLight.shadow.mapSize.height = 5120
      this.scene.add(dirLight)

      this.orbit = new OrbitControls(this.currentCamera, this.renderer.domElement)
      this.orbit.target = new THREE.Vector3(0, 1.3, 0) // set the center
      this.orbit.maxPolarAngle = Math.PI / 2 // prevent the camera from going under the ground
      this.orbit.minDistance = 1 // the minimum distance the camera must have from center
      this.orbit.maxDistance = 100000 // the maximum distance the camera must have from center
      this.orbit.update()
      this.orbit.addEventListener('change', this.render)

      HoverlayThreeUtils.loadReflectionProbe(this)
      await this.createAnchorsRepresentation()
      await this.createHobjectsRepresentation()
      this.objectSelector.setOriginTransform(this.anchorObject)
      this.animate()
      this.isLoading = false
      window.addEventListener('resize', this.onWindowResize)
      // window.addEventListener('mousemove', this.onMouseMove, false)
      window.addEventListener('keydown', this.onKeyDown)
      window.addEventListener('keyup', this.onKeyUp)
    },
    async createHobjectRepresentation(node) {
      const hobject = this.getHobject(node.hobject_pid)
      const modelGroup = new THREE.Object3D()
      modelGroup.name = "modelGroup"
      var model

      switch (hobject.model_identifier) {
        case 'hoverlay.core.Audio.1.0':
          model = await HoverlayThreeUtils.createCoreAudio(hobject, this.renderer, this.render)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        case 'hoverlay.core.UnityAssetBundle.1.0':
          model = await HoverlayThreeUtils.createCoreImage(hobject, this.renderer, this.render)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        case 'hoverlay.core.Button.1.0':
          model = await HoverlayThreeUtils.createCoreButton(hobject, modelGroup, this.gltfLoader)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        case 'hoverlay.core.TextCard.1.0':
        case 'hoverlay.core.Image.1.0':
          model = await HoverlayThreeUtils.createCoreImage(hobject, this.renderer, this.render)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        case 'hoverlay.core.Video.1.0':
        case 'hoverlay.core.ChromaVideo.1.0':
          model = await HoverlayThreeUtils.createCoreVideo(hobject, node, this.threeJSVideoComponents)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        case 'hoverlay.core.3dModel.1.0':
          model = await HoverlayThreeUtils.createCore3dModel(
            hobject,
            node,
            modelGroup,
            this.gltfLoader,
            this.mixerDictionnary
          )

          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        case 'hoverlay.core.Portal.1.0':
          console.log('createHobjectRepresentation')
          console.log(hobject)

          var res = await HoverlayThreeUtils.createCorePortalThumbnail(
            hobject,
            node,
            this.renderer,
            this.render,
            this.threeJSVideoComponents
          )

          model = res.model
          this.createMeshArray(node, model, false)
          modelGroup.add(model)
          break
        case 'hoverlay.core.ImmersionSphere.1.0':
          model = await HoverlayThreeUtils.createImmersiveSphereFromHobject(hobject)
          this.createMeshArray(node, model, false)
          modelGroup.add(model)
          break
        case 'hoverlay.core.PointCloud.1.0':
          model = await HoverlayThreeUtils.createPointCloud(hobject, this.plyLoader)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        default:
          break
      }
      var transform = new THREE.Group()

      transform.add(modelGroup)
      transform.name = node.pid

      // Setup rotation
      if (
        isNaN(node.quaternion_x) ||
        isNaN(node.quaternion_y) ||
        isNaN(node.quaternion_z) ||
        isNaN(node.quaternion_w)
      ) {
        // Using euler
        transform.rotation
        new THREE.Euler(
          THREE.MathUtils.degToRad(-node.angle_x),
          THREE.MathUtils.degToRad(-node.angle_y),
          THREE.MathUtils.degToRad(node.angle_z),
          'ZXY'
        )
      } else {
        // Using quaternion
        var quaternion = new THREE.Quaternion(
          node.quaternion_x,
          node.quaternion_y,
          -node.quaternion_z,
          -node.quaternion_w
        )

        transform.quaternion.copy(quaternion) // Apply Quaternion
        transform.quaternion.normalize() // Normalize Quaternion
      }

      //Setup position
      transform.position.set(node.x, node.y, -node.z)

      //Set scale
      transform.scale.set(node.scale, node.scale, node.scale)
 
      this.render()

      this.transformDictionnary[node.pid] = transform

      this.anchorObject.add(transform)

      // Bounding boxes are not always calculated correctly, especially for animated models.
      // Add a Box3 and a boxHelper, with a properly calculated bounding box to help with raycasting selection
      var bbox = new THREE.Box3();
      bbox = HoverlayThreeUtils.computeBoundingBox(model)

      // Add a boxHelper to the modelGroup. The boxHelper will be used to compute the selection box
      var boxHelper = new THREE.Box3Helper(bbox, 0xff0000)
      boxHelper.name = 'boxHelper' // Please note that the name is used to retrieve the boxHelper in the selection box
      modelGroup.add(boxHelper)
      boxHelper.visible = false

      // For debugging purposes, uncomment the following line to display the bounding box
      // boxHelper.visible = true


      return model
    },
    async createHobjectsRepresentation() {
      for (const node of this.nodes) {
        await this.createHobjectRepresentation(node)
        this.render()
      }
      this.renderer.domElement.addEventListener('mousedown', this.onDocumentMouseDown, false)
    },
    
    addCompassImage() {
      var geometry, material, mesh
      var loader = new THREE.TextureLoader()
      var texture = loader.load(`${window.location.origin}/images/compass.png`)
      geometry = new THREE.PlaneBufferGeometry()
      material = new THREE.MeshBasicMaterial({ map: texture, opacity: 0.4, transparent: true })
      material.side = THREE.DoubleSide
      console.log('addCompassImage')
      // console.log(this.space.settings.heading)
      mesh = new THREE.Mesh(geometry, material)
      mesh.position.set(0, 0.01, 0)
      mesh.scale.set(5, 5, 5)
      mesh.rotation.set(-Math.PI / 2, 0, THREE.MathUtils.degToRad(this.space.settings.heading))
      this.scene.add(mesh)
    },
    addMannequin() {
      var context = this
      // Load a glTF resource
      this.gltfLoader.load(
        // resource URL
        `${window.location.origin}/models/mannequin.glb`,
        // called when the resource is loaded
        function (gltf) {
          // Rescale and place mannequin on the ground
          //gltf.scene.scale.set(150, 150, 150) // scale here
          var mroot = gltf.scene
          var bbox = new THREE.Box3().setFromObject(mroot)
          var cent = bbox.getCenter(new THREE.Vector3())
          var size = bbox.getSize(new THREE.Vector3())
          bbox.setFromObject(mroot)
          bbox.getCenter(cent)
          bbox.getSize(size)
          //Reposition to 0,halfY,0
          mroot.position.copy(cent).multiplyScalar(-1)
          mroot.position.y += size.y * 0.5

          context.mannequinObject = gltf.scene
          context.scene.add(gltf.scene)

          // Play animation
          context.mixerDictionnary['origin'] = new THREE.AnimationMixer(gltf.scene)
          var action = context.mixerDictionnary['origin'].clipAction(gltf.animations[0])
          action.clampWhenFinished = true
          action.loop = THREE.LoopOnce
          action.play()

          gltf.animations // Array<THREE.AnimationClip>
          gltf.scene // THREE.Group
          gltf.scenes // Array<THREE.Group>
          gltf.cameras // Array<THREE.Camera>
          gltf.asset // Object

          // gltf.scene.traverse(function(child) {
          //   if (child.isMesh) {
          //     child.material = new THREE.MeshBasicMaterial({
          //       color: 'red',
          //       transparent: true,
          //       opacity: 0.6,
          //     })
          //   }
          // })
          // Adjust model position
          gltf.scene.position.x = 0.07
          gltf.scene.position.z = 0.05

          gltf.scene.rotateY(-Math.PI)
          context.render()
        },
        // called while loading is progressing
        // eslint-disable-next-line no-unused-vars
        function (xhr) {
          // - // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
        },
        // called when loading has errors
        // eslint-disable-next-line no-unused-vars
        function (error) {
          // console.log(error)
          // console.log('An error happened')
        }
      )
    },
    onDraggingChanged(event) {

      var dragging = event.detail.dragging
      var group = event.detail.group
      var mode = event.detail.mode
      var axis = event.detail.axis

      if (dragging) {
        this.interacting = true
        this.orbit.enabled = false
        this.distanceHelper.attach(group, this.anchorObject, mode, axis)
      }
      else {
        this.distanceHelper.detach()
        this.orbit.enabled = true
        this.interacting = false
      }

      // if (dragging) {
      //   this.orbit.enabled = false
      // } else {
      //   this.orbit.enabled = true
      //   this.interacting = false
      // }
    },
    interactionStarted(event) {
     this.interacting = true
    },
    interactionUpdated(event) {
      // We need to display the relative position of the group to the anchor object in Unity coordinates
      // 1. Get the group matrix
      const groupMatrix = event.detail.matrixWorld
      const anchorMatrix = this.anchorObject.matrixWorld
      // 2. Compute the inverse matrix of the anchor object
      var inverseMatrix1 = anchorMatrix.clone().invert()
      // 3. Compute the relative position matrix
      const relativePositionMatrix = inverseMatrix1.multiply(groupMatrix)
      // 4. Convert the relative position matrix to unity coordinates
      var convertedTransform = HoverlayThreeUtils.threejsMatrixToUnity(relativePositionMatrix)

      //console.log("INTERACTION UPDATED", convertedTransform.x, convertedTransform.y, convertedTransform.z)
    },
    interactionEnded(event) {
      var command;

      // If no node is selected, return
      if (this.selectedNodes.length == 0) return

      // If a single node is selected, create a single command
      if (this.selectedNodes.length == 1) {

        var t = event.detail.selection[0]

        var n = this.nodes.find(node => node.pid === t.name)

        command = new Command.TransformNodeToCommand(n, HoverlayThreeUtils.threejsObjectToUnity(t))
    }
     else {

        command = new Command.NodeGroupCommand()

        event.detail.selection.forEach(t => {

          var n = this.nodes.find(node => node.pid === t.name)

          command.add(new Command.TransformNodeToCommand(n, HoverlayThreeUtils.threejsObjectToUnity(t)))
        })
      }
      
      this.$emit('updateNodeTransform', command)
    },
    onWindowResize() {
      this.CANVAS_HEIGHT = document.getElementById('scene3d').offsetHeight
      this.CANVAS_WIDTH = document.getElementById('scene3d').offsetWidth
      const aspect = this.CANVAS_WIDTH / this.CANVAS_HEIGHT
      this.currentCamera.aspect = aspect
      this.currentCamera.updateProjectionMatrix()
      this.renderer.setSize(this.CANVAS_WIDTH, this.CANVAS_HEIGHT)
      this.composer.setSize(this.CANVAS_WIDTH, this.CANVAS_HEIGHT)
      // Reset shader used for outlines
      this.fxaaShader.uniforms["resolution"].value.set(1 / this.CANVAS_WIDTH, 1 / this.CANVAS_HEIGHT);

      this.render()
    },
    onKeyUp(event) {
      switch (event.keyCode) {
        case 16: // Shift
          if (this.objectSelector) {
            this.objectSelector.setTranslationSnap(null)
            this.objectSelector.setRotationSnap(null)
            this.objectSelector.setScaleSnap(null)
          }
          break
      }
    },
    // event.ctrlKey && event.key === 'z'
    onKeyDown(event) {
      
      switch (event.keyCode) {
        case 81: // Q
          // self.controls.forEach(control => {
          //   control.setSpace(control.space === 'local' ? 'world' : 'local')
          // })
          break
        case 16: // Shift
          if (this.objectSelector) {
            // set translation snap to either 10cm or 5 inches based on user prefered measurement system
            this.objectSelector.setTranslationSnap(this.$store.state.AppActiveUser.measurement == 'metric' ? 0.25 : 6 * INCHES_TO_METERS) // 25 cm if metrics, 6 inches if imperial
            this.objectSelector.setRotationSnap(THREE.MathUtils.degToRad(15)) // 15 degrees steps
            this.objectSelector.setScaleSnap(0.25)
          }
          break
        case 77: // M
          this.controlModeFloor = 'translate'
          break
        case 82: // R
          this.controlModeFloor = 'rotate'
          break
        case 69: // E
          this.objectSelector.alignTop()
          break
        case 70: // F
          this.focusOnSelectedNodes()
          break
        case 83: // S
          this.controlModeFloor = 'scale'
          break
        case 86: // V - First-Person View
          // Only trigger if it's not part of Ctrl+V combination
          if (!event.ctrlKey && !event.metaKey) {
            this.isFirstPersonView = !this.isFirstPersonView
          }
          break;
        case 187:
        case 107: // +, =, num+
          this.control.setSize(this.control.size + 0.1)
          break
        case 189:
        case 109: // -, _, num-
          this.control.setSize(Math.max(this.control.size - 0.1, 0.1))
          break
          
      }
    },
     // gradually orbit and zoom the camera to focus on the selected nodes
    focusOnSelectedNodes() {
      if (this.selectedNodes.length == 0) return

      var target = new THREE.Vector3()
      var boundingBox = new THREE.Box3()

      this.selectedNodes.forEach(node => {
        var transform = this.transformDictionnary[node.pid]
        if (transform) {
          boundingBox.expandByObject(transform)
        }
      })

      boundingBox.getCenter(target)
      this.orbit.target = target // set the center

// Calculate the closest camera position and rotation that would focus on the selected nodes

      var distance = boundingBox.getSize(new THREE.Vector3()).length()

      // Calculate the original distance needed to view the whole object
      distance += distance / Math.tan((this.currentCamera.fov / 2) * (Math.PI / 180.0))
      // Bring the camera closer to make the bounding box cover about half the viewport
      distance = distance / 1.8;  // Adjust this divisor to control how much of the viewport the object covers

      var direction = new THREE.Vector3()
      var position = new THREE.Vector3()
      direction = this.currentCamera.getWorldDirection(direction)
      direction.multiplyScalar(distance)

      position.copy(target).sub(direction)

      var tween = new TWEEN.Tween(this.currentCamera.position)
        .to(position, 450)
        .easing(TWEEN.Easing.Quadratic.Out)
        .start()

      tween.onUpdate(() => {
        this.currentCamera.lookAt(target)
        this.render()
      })
    },
    render() {
      if (this.threeJSVideoComponents)
        this.threeJSVideoComponents.forEach(el => {
          // - // console.log( el.video)

          if (el.video.readyState == el.video.HAVE_ENOUGH_DATA) {
            el.videoImageContext.drawImage(el.video, 0, 0)
            if (el.videoTexture) el.videoTexture.needsUpdate = true
          }
        })

      //If we have selected objects, update the selection (outline, etc.)
      if (this.objectSelector && !this.objectSelector.isEmpty()) {
        // this.objectSelector.refresh()
        this.distanceHelper.update()
      }

      this.composer.render();

      if (this.captureThumbnail == true) {
        // console.log(this.renderer.domElement)
        // var strMime = 'image/jpeg'
        // this.space.thumbnail = this.renderer.domElement.toDataURL(strMime)
        // this.onThumbnailReady()
        // this.captureThumbnail = false
      }

     //updateAnnotationOpacity()
     // this.updateScreenPosition()
    },
    // updateAnnotationOpacity() {
    //   const meshDistance = this.camera.position.distanceTo(this.objectSelector.group.position)
    //   const spriteDistance = this.camera.position.distanceTo(sprite.position)
    //   spriteBehindObject = spriteDistance > meshDistance
    //   sprite.material.opacity = spriteBehindObject ? 0.25 : 1

    //   // Do you want a number that changes size according to its position?
    //   // Comment out the following line and the `::before` pseudo-element.
    //   sprite.material.opacity = 0
    // },
    updateScreenPosition() {
      if (!this.objectSelector || this.objectSelector.isEmpty()) {
        this.annotation.style.opacity = 0
        return
      }
      this.annotation.style.opacity = 1
      this.annotation.textContent = this.selectedNodes[0]

      const vector = this.objectSelector.group.clone().position
      const canvas = this.renderer.domElement

      vector.project(this.currentCamera)

      vector.x = Math.round((0.5 + vector.x / 2) * (canvas.width / window.devicePixelRatio))
      vector.y = Math.round((0.5 - vector.y / 2) * (canvas.height / window.devicePixelRatio))

      this.annotation.style.top = `${vector.y}px`
      this.annotation.style.left = `${vector.x}px`
      //console.log(this.annotation.style.top, this.annotation.style.left)
      //this.annotation.style.opacity = spriteBehindObject ? 0.25 : 1
    },
onThumbnailReady() { },
    positionChanged() { },
    reset() {
      this.setupScene()
      this.addMannequin()
      if (this.space.settings && this.space.settings.compass_oriented) this.render()
    },
    getHobject(hobject_pid) {
      return JSON.parse(
        JSON.stringify(this.$store.state.hoverlay.hobjects.filter(hobject => hobject.pid == hobject_pid)[0])
      )
    },
    stopRendering() {
      cancelAnimationFrame(this.requestAnimationFrameId)
      this.renderer.setAnimationLoop(null) // pause the animation
    },
    disposeScene() {

      cancelAnimationFrame(this.requestAnimationFrameId)
      this.renderer.setAnimationLoop(null) // pause the animation

      this.objectSelector.removeEventListener('interaction-started', event => this.interactionStarted(event))
      this.objectSelector.removeEventListener('interaction-updated', event => this.interactionUpdated(event))
      this.objectSelector.removeEventListener('interaction-ended', event => this.interactionEnded(event))
      this.objectSelector.removeEventListener('dragging-changed', event => this.onDraggingChanged(event))

      // Dispose of objectSelector
      this.objectSelector.dispose()

      // Dispose of distanceHelper
      this.distanceHelper.dispose()

      // Others events
      this.renderer.domElement.removeEventListener('click', this.onDocumentMouseDown, false)
      this.orbit.removeEventListener('change', this.render)
      window.removeEventListener('resize', this.onWindowResize)
      window.removeEventListener('keydown', this.onKeyDown)
      window.removeEventListener('keyup', this.onKeyUp)

      DestroyUtils.destroyThreejs({ scene: this.scene, renderer: this.renderer, camera: this.currentCamera })
      // remove reference on global attributes
      // Three js variables
      this.cameraPersp = null
      this.currentCamera = null
      this.scene = null
      this.meshes = []
      this.renderer = null
      this.outline = null
      this.objectSelector = null
      this.distanceHelper = null
      this.anchorObject = null
      this.composer = null
      this.fxaaShader = null
      this.transformDictionnary = []
      this.orbit = null
      this.CANVAS_HEIGHT = null
      this.CANVAS_WIDTH = null
      this.mannequinObject = null
      this.isFirstPersonView = false
      this.thirdPersonCoordinates = null
      this.thirdPersonOrbitTarget = null
      this.thirdPersonAngleX = null
      this.thirdPersonAngleY = null
      this.thirdPersonAngleZ = null
      //RAYCASTING
      this.raycaster = null
      this.mouse = null
      this.threeJSVideoComponents = []
      this.clock = null
      this.mixer = null
      this.mixerDictionnary = null
      return
    },
    animate() {
      const mixerUpdateDelta = this.clock.getDelta()
      if (this.mixerDictionnary)
        for (const mixer of Object.values(this.mixerDictionnary)) {
          mixer.update(mixerUpdateDelta)
        }
      this.requestAnimationFrameId = requestAnimationFrame(this.animate)
      TWEEN.update()
      this.render()
    },
    createMeshArray(node, model, castShadow = true) {
      var self = this
      model.traverse(function (child) {
        if (child.isMesh) {
          child.name = node.pid
          child.castShadow = castShadow
          self.meshes.push(child)
        }
      })
    },
    onDocumentMouseDown(event) {
      // void raycast if transform control is in use (avoid event propagation)
      if (this.interacting) return

      var canvasPosition = this.renderer.domElement.getBoundingClientRect()
      var mouseX = event.clientX - canvasPosition.left
      var mouseY = event.clientY - canvasPosition.top
      var mouse = new THREE.Vector3(
        2 * (mouseX / this.renderer.domElement.clientWidth) - 1,
        1 - 2 * (mouseY / this.renderer.domElement.clientHeight),
        0.5
      )
      var raycaster = new THREE.Raycaster(
        this.currentCamera.position,
        mouse
          .unproject(this.currentCamera)
          .sub(this.currentCamera.position)
          .normalize()
      )

      var intersects = raycaster.intersectObjects(this.meshes, true)

      if (intersects.length > 0) {
        // User clicked on something
        this.on3dObjectClicked(event, intersects[0].object)
      }
    },
    on3dObjectClicked(event, object) {

      var node = this.nodes.find(node => JSON.stringify(node.pid) == JSON.stringify(object.name));
      if (!event.shiftKey) {
        // If the object is not in selection, deselect all nodes and select the node clicked
        if (!this.objectSelector.isSelected(object.name)) {
          for (const node of this.selectedNodes) {
            this.$emit('removeNodeFromSelection', node)
          }
        }

        // If object is the only one selected, deselect it
        // if (this.objectSelector.size() == 1 && this.objectSelector.isSelected(object.name)) {
        //   this.$emit('removeNodeFromSelection', node)
        //   return
        // }
        // if (!this.objectSelector.isEmpty()) { // Deselect all nodes if there is a selection
        //   for (const node of this.selectedNodes) {
        //     this.$emit('removeNodeFromSelection', node)
        //   }
        // }
        this.$emit('addNodeToSelection', node)
      }
      else {
        // If shift key is pressed, toggle the node clicked
        if (this.objectSelector.isSelected(node.pid)) // If node is already selected, remove it from selection
          this.$emit('removeNodeFromSelection', node)
        else
          this.$emit('addNodeToSelection', node) // Otherwise add it to selection
      }
    },
    async createAnchorsRepresentation() {

      this.anchorObject = new THREE.Group();
      // The anchor object defines the cartesian coordinate system of children object attached to the tracked image anchor. Y is orthogonal to the image. 
      this.anchorObject.rotation.set(0, 0, 0)
      this.anchorObject.position.set(0, 0, 0)

      this.scene.add(this.anchorObject)
    },
    // For each node in nodes, check if they are in the objectSelector list. 
    // If some nodes are in objectSelector list but not in nodes, remove them from the objectSelector list.
    // If they are not, add them to the objectSelector list. 
    updateSelectedObjects(nodes) {
      if (!this.objectSelector.isEmpty()) {
        for (const obj of this.objectSelector.selectedObjects()) {
          var node = nodes.find(n => n.pid == obj.name)
          if (node == null) {
            this.RemoveObjectFromSelection(obj)
          }
        }
      }
      for (const node of nodes) {
        if (!this.objectSelector.isSelected(node.pid)) {
          this.AddObjectToSelection(this.transformDictionnary[node.pid])
        }
      }
      // Add selected objects to outline
      this.outline.selectedObjects = this.objectSelector.selectedObjects();
    },
    AddObjectToSelection(object) {
      if (object == null) return // not a valid object

      if (this.objectSelector.isSelected(object.name)) return // already selected

      this.objectSelector.add(object) //Add 3D object to selection

      // Unmute video if selected
      var video = document.getElementById(`video_${object.name}`)
      if (video) video.muted = false
    },
    RemoveObjectFromSelection(object) {
      if (object == null) return
      if (!this.objectSelector.isSelected(object.name)) return

      this.objectSelector.remove(object) // Remove node from selection
      
      // Mute video if unselected
      var video = document.getElementById(`video_${object.name}`)
      if (video) video.muted = true
    },
  }
}
</script>

<style src="@/assets/css/annotation.css"></style>
<style lang="scss">

#div-with-loading {
  height: 50px;
  width: 50px;
}
#div-with-loading > .con-vs-loading {
  background-color: rgba(255, 255, 128, 0);
}
.center-top-right-loading {
  right: 0;
  text-align: right;
  position: absolute;
  top: 0;
}
.center-top-right {
  right: 0;
  width: 300px;
  text-align: right;
  position: absolute;
  top: 0;
  padding-right: 10px;
}

.center-top-left {
  width: 50px;
  text-align: left;
  position: absolute;
  padding-left: 10px;
  top: 0;
}

.pov {
  display: flex;
  gap: 5px;
}

.povBottomLeft {
  display: flex;
  padding-left: 10px;
  text-align: left;
  position: absolute;
  bottom: 5px;
  gap: 5px;
}

.povBottomRight {
  display: flex;
  padding-right: 10px;
  text-align: right;
  position: absolute;
  bottom: 5px;
  gap: 5px;
  right: 0;
}

.no-padding {
  padding: 0rem !important;
}

#scene3d {
  // height: 550px;
  // position: static;
  /* fixed or static */
  background: transparent;
  position: relative;
  z-index: 0;
}

.firstPersonView {
  display: flex;
  flex-direction: row;
  z-index: 1;
}

#iPhoneUi {
  position: absolute;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.8;
  pointer-events: none;
}

.grey {
  position: fixed;
  z-index: 2;
  background-color: #878787;
  opacity: 0.5;
  height: 400px;
  width: 650px;
  // -webkit-clip-path: polygon(16% 0%, 93% 0%, 75% 100%, 16% 100%);
  // clip-path: polygon(16% 0%, 93% 0%, 75% 100%, 16% 100%);
  // clip-path: require('../../../../../assets/images/hoverlay/iPhone-Ui.png');
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: auto;
  // margin-bottom: auto;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
}
.annotation {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    margin-left: 15px;
    margin-top: 15px;
    padding: 1em;
    width: 200px;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    border-radius: .5em;
    font-size: 12px;
    line-height: 1.2;
    transition: opacity .5s;
    &::before {
        content: '1';
        position: absolute;
        top: -30px;
        left: -30px;
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        border-radius: 50%;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        background: rgba(0, 0, 0, 0.8);
    }
}

#number {
    position: absolute;
    z-index: -1;
}
</style>
