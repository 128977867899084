<!-- =========================================================================================
    File Name: Position.vue
    Description: Page to change the node xyz position in a given space
========================================================================================== -->

<template>
  <div>
    <vx-card class="card-no-padding">
      <center class="mt-2 center-top-left">
        <ul>
          <li>
            <vs-radio v-model="controlMode" vs-value="translate">Move</vs-radio>
          </li>
          <li>
            <vs-radio v-model="controlMode" vs-value="rotate">Rotate</vs-radio>
          </li>
          <li>
            <vs-radio v-model="controlMode" vs-value="scale">Scale</vs-radio>
          </li>
        </ul>
      </center>
      <!-- <div class="center-top-right" v-if="this.renderer">{{ this.renderer.info.memory }}</div> -->
      <div v-if="isLoading" class="center-top-right-loading">
        <div class="contained-example-container">
          <div id="div-with-loading" class="vs-con-loading__container"></div>
        </div>
      </div>
      <!-- <center class="center-bottom-left mb-3">
        <div class="vx-col w-full">
          <vx-tooltip text="Make all the object transparent (Editor only)">
            <div class="vx-row">
              <div class="vx-col w-3/12">
                <vs-switch color="success" v-model="use_transparency">
                  Use transparency
                  <span slot="on">On</span>
                  <span slot="off">Off</span>
                </vs-switch>
              </div>
              <div class="vx-col w-9/12">Transparency</div>
            </div>
          </vx-tooltip>
        </div>
      </center> -->
      <div class="scene3d" :id="imageAnchor.pid"></div>
    </vx-card>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { Grid } from '@pmndrs/vanilla'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js'
import * as TWEEN from '@tweenjs/tween.js'
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";

import { OutlinePass2 } from "@/assets/js/utils/OutlinePass2.js";

import * as HoverlayThreeUtils from '@/assets/js/utils/hoverlay-three-utils.js'
import * as Command from '@/assets/js/utils/commands.js'
import { ObjectSelector } from '@/assets/js/utils/object-selector.js'
import { DistanceHelper } from '@/assets/js/utils/distance-helper.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import * as DestroyUtils from '@/assets/js/utils/destroy-utils.js'
import * as Utils from '@/assets/js/utils/utils.js'
import { UnitConverter } from '@/assets/js/utils/unit-converter.js'
import { YARDS_TO_METERS, METERS_TO_YARDS, INCHES_TO_METERS } from '@/assets/js/utils/unit-converter.js'

import 'video.js/dist/video-js.css'
import 'flatpickr/dist/flatpickr.css'
//import { image } from 'html2canvas/dist/types/css/types/image'

export default {
  components: {},
  props: {
    selectedNodes: [],
    nodes: {},
    imageAnchor: {},
    hobjects: {},
  },
  computed: {
    selectedNodesCopy() {
      return [...this.selectedNodes];
    },
  },
  data() {
    return {
      // Variables for selections
      meshes: [],
      transformDictionnary: [],
      //
      anchor: null,
      hobject: null,
      // usefull to make the camera fit the whole scene
      highest3dObject: 0,
      // Three js variables
      scene3d: null,
      cameraPersp: null,
      cameraOrtho: null,
      currentCamera: null,
      scene: null,
      renderer: null,
      control: null,
      orbit: null,
      CANVAS_HEIGHT: null,
      CANVAS_WIDTH: null,
      anchorObject: null,
      trackedImagePixelWidth: null,
      trackedImagePixelHeight: null,
      trackedImageHeight: 1,
      font: null,
      distanceTextGeometry: null,
      distanceTextMaterial: null,
      distanceTextMesh: null,
      hobjectThumbnailPixelWidth: null,
      hobjectThumbnailPixelHeight: null,
      transformControlDictionnary: [],
      controlMode: 'translate',
      threeJSVideoComponents: [],
      clock: null,
      mixer: null,
      mixerDictionnary: [],
      gltfLoader: null,
      dracoLoader: null,
      isLoading: true,
      objectSelector: null,
    }
  },
  watch: {
    controlMode: function(mode) {
      this.objectSelector.changeTransformControlMode(mode)
    },
    selectedNodesCopy(newNodes, oldNodes) {
      // Update the selected 3D objects in the Three.js scene
      this.updateSelectedObjects(newNodes);
    },
    nodes: function(newNodes, oldNodes) {
      // Indentify nodes that have been ADDED and add them on the threeJS scene
      var onlynewNewNodes = newNodes.filter(HoverlayUtils.spacesComparer(oldNodes))
      for (const node of onlynewNewNodes) {
        this.createHobjectRepresentation(node)
      }

      // Indentify nodes that have been removed and remove them from the threeJS scene
      // Detach transform first and then remove object from the scene
      var onlyInOldNodes = oldNodes.filter(HoverlayUtils.spacesComparer(newNodes))

      onlyInOldNodes.forEach(node => {

        if (this.objectSelector.isSelected(node.pid))
          this.objectSelector.remove(node)

        if (this.transformDictionnary[node.pid].parent)
          this.transformDictionnary[node.pid].parent.remove(this.transformDictionnary[node.pid])
        else this.scene.remove(this.transformDictionnary[node.pid])
        this.meshes = this.meshes.filter(mesh => mesh.name !== node.pid)
        delete this.mixerDictionnary[node.pid]
        DestroyUtils.disposeHierarchy(
          {
            node: this.transformDictionnary[node.pid],
          },
          DestroyUtils.disposeNode
        )
        this.transformDictionnary[node.pid] = null
        delete this.transformDictionnary[node.pid]
        var selectedObject = this.scene.getObjectByName(node.pid)
        this.scene.remove(selectedObject)

        this.render()
      })
    },
    selectedNode: function(selectedNode) {
      if (selectedNode && selectedNode.pid && this.transformDictionnary[selectedNode.pid]) {
        // If a video is found unmuted it
        var video = document.getElementById(`video_${selectedNode.pid}`)
        if (video) video.muted = false
      }
    },
  },
  // https://sketchfab.com/3d-models/wooden-artist-mannequin-be47b21c2e8f4a0f975e89a9c50a4aa5
  async mounted() {
    this.$vs.loading({
      container: '#div-with-loading',
      scale: 0.6,
    })

    this.clock = new THREE.Clock()

    // Optional: Provide a DRACOLoader instance to decode compressed mesh data
    this.gltfLoader = new GLTFLoader()
    this.dracoLoader = new DRACOLoader()
    this.dracoLoader.setDecoderPath(`${window.location.origin}/js/draco/`)
    this.gltfLoader.setDRACOLoader(this.dracoLoader)

    this.plyLoader = new PLYLoader()

    await this.$nextTick()
    await this.setupScene()

    this.$eventBus.$on('nodeTransformChanged', this.onNodeTransformChanged)
  },

  beforeDestroy() {
    this.$eventBus.$off('nodeTransformChanged')

    this.disposeScene()
  },
  methods: {

    // Update the selected 3D objects in the Three.js scene

    onNodeTransformChanged(node) {
      var transform = this.transformDictionnary[node.pid]

      if (transform == null) return

      // change position if needed
      if (transform.position.x != node.x) transform.position.x = node.x
      if (transform.position.y != node.y) transform.position.y = node.y
      if (transform.position.z != -node.z) transform.position.z = -node.z
      // change scale if needed
      if (transform.scale.x != node.x || transform.scale.y != node.y || transform.scale.z != node.z) {
        transform.scale.z = node.scale
        transform.scale.x = node.scale
        transform.scale.y = node.scale
      }

      // If transform world quaternion rotation is different from node rotation, update the transform
      var worldQuaternion = new THREE.Quaternion();
      transform.getWorldQuaternion(worldQuaternion);
      if (worldQuaternion.x != node.quaternion_x ||
        worldQuaternion.y != node.quaternion_y ||
        worldQuaternion.z != -node.quaternion_z ||
        worldQuaternion.w != -node.quaternion_w) {

        var quaternion = new THREE.Quaternion(
          node.quaternion_x,
          node.quaternion_y,
          -node.quaternion_z,
          -node.quaternion_w
        )

        transform.quaternion.copy(quaternion) // Apply Quaternion
        transform.quaternion.normalize() // Normalize Quaternion
      }

      this.objectSelector.updateGizmos()

    },
    onSpaceSaved() {
      this.captureThumbnail = true
    },
    async setupScene() {
      console.log("::::",THREE.REVISION)
      this.scene3d = document.getElementById(this.imageAnchor.pid.toString())
      if (this.scene3d.firstChild) {
        this.scene3d.firstChild.remove()
      }
      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true })
      this.renderer.setPixelRatio(window.devicePixelRatio)

      this.CANVAS_HEIGHT = this.scene3d.offsetHeight
      this.CANVAS_WIDTH = this.scene3d.offsetWidth

      this.renderer.setSize(this.CANVAS_WIDTH, this.CANVAS_HEIGHT)
      this.scene3d.appendChild(this.renderer.domElement)

      const aspect = this.CANVAS_WIDTH / this.CANVAS_HEIGHT

      this.cameraPersp = new THREE.PerspectiveCamera(50, aspect, 0.01, 30000)
      this.cameraOrtho = new THREE.OrthographicCamera(-600 * aspect, 600 * aspect, -600, 600, 0.01, 30000)
      this.currentCamera = this.cameraPersp

      this.currentCamera.position.set(-0.15, 0.05, 0.3)
      this.currentCamera.lookAt(0, 0, 0)

      this.scene = new THREE.Scene()

      this.composer = new EffectComposer(this.renderer);
      const renderPass = new RenderPass(this.scene, this.currentCamera);

      this.composer.addPass(renderPass);

      this.outline = new OutlinePass2(new THREE.Vector2(this.CANVAS_WIDTH, this.CANVAS_HEIGHT), this.scene, this.currentCamera);
      this.outline.edgeThickness = 10;
      this.outline.edgeStrength = 5;
      this.outline.edgeGlow = 2;
      this.outline.usePatternTexture = false;
      // this.outline.visibleEdgeColor.set(0x7367F0);
      // this.outline.hiddenEdgeColor.set(0x7367F0);
      // this.outline.visibleEdgeColor.set(0x006FC5);
      // this.outline.hiddenEdgeColor.set(0x006FC5);
      this.outline.visibleEdgeColor.set(0xf7c100);
      this.outline.hiddenEdgeColor.set(0xf7c100);
      
      this.composer.addPass(this.outline);

        this.fxaaShader = new ShaderPass(FXAAShader);
        const pixelRatio = this.renderer.getPixelRatio();
        this.fxaaShader.uniforms["resolution"].value.set(1 / (this.CANVAS_WIDTH * pixelRatio), 1 / (this.CANVAS_HEIGHT * pixelRatio));
        this.composer.addPass(this.fxaaShader);

      // Hoverlay custom object selector
      this.objectSelector = new ObjectSelector(this.scene, this.currentCamera, this.renderer.domElement, this.$store.state.AppActiveUser.measurement, false, true);
      this.objectSelector.addEventListener('interaction-started', event => this.interactionStarted(event))
      this.objectSelector.addEventListener('interaction-updated', event => this.interactionUpdated(event))
      this.objectSelector.addEventListener('interaction-ended', event => this.interactionEnded(event))
      this.objectSelector.addEventListener('dragging-changed', event => this.onDraggingChanged(event))
      this.interacting = false

      this.distanceHelper = new DistanceHelper(
        this.scene,
        this.currentCamera,
        this.renderer.domElement,
        this.$store.state.AppActiveUser.measurement,
        true
      )

      const light = new THREE.AmbientLight(0xf4f4f4) // soft white light
      this.scene.add(light)

      // How far you can orbit vertically
      // this.orbit.minPolarAngle = Math.PI / 6
      // this.orbit.maxPolarAngle = Math.PI / 1.2

      // How far you can orbit horizontally
      // this.orbit.minAzimuthAngle = -Math.PI / 2
      // this.orbit.maxAzimuthAngle = Math.PI / 2

      this.orbit = new OrbitControls(this.currentCamera, this.renderer.domElement)
      this.orbit.autoRotate = false
      this.orbit.maxPolarAngle = Math.PI // prevent the camera from going under the ground
      this.orbit.minPolarAngle = 0 // prevent the camera from going under the ground
      this.orbit.minDistance = 0.1 // the minimum distance the camera must have from center
      this.orbit.maxDistance = 200 // the maximum distance the camera must have from center

      this.orbit.update()
      this.orbit.addEventListener('change', this.render)

      HoverlayThreeUtils.loadReflectionProbe(this)
      await this.createTrackedImageRepresentation()
      await this.createHobjectsRepresentation(this.nodes)
      this.objectSelector.setOriginTransform(this.anchorObject)
      this.animate()
      this.isLoading = false
      window.addEventListener('keydown', this.onKeyDown)
      window.addEventListener('keyup', this.onKeyUp)
      window.addEventListener('resize', this.onWindowResize)
      this.adjustCameraDistance()

      // Compute the grid size based on the highest 3d object. 
      // Set the cell size to 0.1 meter if the highest object is less than 1 meter, and so on
      var cellSize
      if (this.highest3dObject < 1) {
        cellSize = 0.1
      } else if (this.highest3dObject < 25) {
        cellSize = 1
      } else if (this.highest3dObject < 100) {
        cellSize = 10
      } else {
        cellSize = 1
      }

      const grid = Grid({
        args: [cellSize * 10, cellSize * 10],
        cellSize: this.$store.state.AppActiveUser.measurement == 'metric' ? cellSize : cellSize * YARDS_TO_METERS,  // 1 meter if metric. 1 yard if imperial,
        cellThickness: 1,
        cellColor: new THREE.Color('#878787'),
        sectionSize: this.$store.state.AppActiveUser.measurement == 'metric' ? 5 * cellSize : 5 * cellSize * YARDS_TO_METERS,  // 1 meter if metric. 1 yard if imperial,
        sectionThickness: 2.3,
        sectionColor: new THREE.Color('#cdcdcd'),
        fadeDistance: cellSize * 10 * 5,
        fadeStrength: 4,
        followCamera: false,
        infiniteGrid: true,
      })
      // add an offset to the grid to make it appear below the objects
      grid.mesh.position.z = -0.002

      // rotate the grid to be vertical
      grid.mesh.rotation.x = Math.PI / 2

      this.scene.add(grid.mesh)

    },
    async createHobjectRepresentation(node) {
      const hobject = this.getHobject(node.hobject_pid)
      const modelGroup = new THREE.Object3D()
      modelGroup.name = "modelGroup"
      var model
      switch (hobject.model_identifier) {
        case 'hoverlay.core.Audio.1.0':
          model = await HoverlayThreeUtils.createCoreAudio(hobject, this.renderer, this.render)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        case 'hoverlay.core.UnityAssetBundle.1.0':
          model = await HoverlayThreeUtils.createCoreImage(hobject, this.renderer, this.render)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        case 'hoverlay.core.Button.1.0':
          model = await HoverlayThreeUtils.createCoreButton(hobject, modelGroup, this.gltfLoader)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        case 'hoverlay.core.TextCard.1.0':
        case 'hoverlay.core.Image.1.0':
          model = await HoverlayThreeUtils.createCoreImage(hobject, this.renderer, this.render)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        case 'hoverlay.core.Video.1.0':
        case 'hoverlay.core.ChromaVideo.1.0':
          model = await HoverlayThreeUtils.createCoreVideo(hobject, node, this.threeJSVideoComponents)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        case 'hoverlay.core.3dModel.1.0':
          model = await HoverlayThreeUtils.createCore3dModel(
            hobject,
            node,
            modelGroup,
            this.gltfLoader,
            this.mixerDictionnary
          )
          this.createMeshArray(node, model)

          modelGroup.add(model)
          break
        case 'hoverlay.core.Portal.1.0':
          var res = await HoverlayThreeUtils.createCorePortalThumbnail(
            hobject,
            node,
            this.renderer,
            this.render,
            this.threeJSVideoComponents
          )

          model = res.model
          this.createMeshArray(node, model, false)
          modelGroup.add(model)
          break
        case 'hoverlay.core.ImmersionSphere.1.0':
          model = await HoverlayThreeUtils.createImmersiveSphereFromHobject(hobject)
          this.createMeshArray(node, model, false)
          modelGroup.add(model)
          break
        case 'hoverlay.core.PointCloud.1.0':
          model = await HoverlayThreeUtils.createPointCloud(hobject, this.plyLoader)
          this.createMeshArray(node, model)
          modelGroup.add(model)
          break
        default:
          break
      }
      const transform = new THREE.Group()

      transform.add(modelGroup)
      transform.name = node.pid

      // Setup pivot point
      // mesh.position.set(0, this.hobjectHeight / 2, 0)

      // Setup rotation
      if (
        isNaN(node.quaternion_x) ||
        isNaN(node.quaternion_y) ||
        isNaN(node.quaternion_z) ||
        isNaN(node.quaternion_w)
      ) {
        // Using euler
        // Setup rotation
        // transform.rotation.set(
        //   THREE.MathUtils.degToRad(-node.angle_x + 90),
        //   THREE.MathUtils.degToRad(-node.angle_y),
        //   THREE.MathUtils.degToRad(node.angle_z)
        // )
      } else {
        // Using quaternion
        var quaternion = new THREE.Quaternion(
          node.quaternion_x,
          node.quaternion_y,
          -node.quaternion_z,
          -node.quaternion_w
        )
        transform.quaternion.copy(quaternion) // Apply Quaternion
        transform.quaternion.normalize() // Normalize Quaternion
      }

      //Setup position
      transform.position.set(node.x, node.y, -node.z)

      //Set scale
      transform.scale.set(node.scale, node.scale, node.scale)
 
      this.render()
      
      this.transformDictionnary[node.pid] = transform

      this.anchorObject.add(transform)

      // Bounding boxes are not always calculated correctly, especially for animated models.
      // Add a Box3 and a boxHelper, with a properly calculated bounding box to help with raycasting selection
      var bbox = new THREE.Box3();
      bbox = HoverlayThreeUtils.computeBoundingBox(model)

      // Add a boxHelper to the modelGroup. The boxHelper will be used to compute the selection box
      var boxHelper = new THREE.Box3Helper(bbox, 0xff0000)
      boxHelper.name = 'boxHelper' // Please note that the name is used to retrieve the boxHelper in the selection box
      modelGroup.add(boxHelper)
      boxHelper.visible = false

      // For debugging purposes, uncomment the following line to display the bounding box
      // boxHelper.visible = true

      // const selectionBox = new SelectionBox(model, null, 'orange', 0.004) // Custom color and linewidth
      // var selectionBoxHelper = selectionBox.createBox()
      // selectionBoxHelper.visible = true
      //modelGroup.add(selectionBoxHelper)
      
      return model
    },
    createMeshDictionnary(node, model) {
      var self = this
      model.traverse(function(child) {
        if (child.isMesh) {
          child.name = node.pid
          self.meshes.push(child)
        }
      })
    },
    adjustCameraDistance() {
      var fov = this.currentCamera.fov * (Math.PI / 180)
      var distance = Math.abs(this.highest3dObject / Math.sin(fov / 2))
      this.currentCamera.position.set(this.currentCamera.position.x - distance/10 , this.currentCamera.position.y, distance)
      this.orbit.maxDistance = distance * 5 // the maximum distance the camera must have from center
      this.orbit.update()
      this.currentCamera.lookAt(0, 0, 0)
    },
    onKeyDown(event) {
      var self = this

      switch (event.keyCode) {
        case 81: // Q
          self.control.setSpace(self.control.space === 'local' ? 'world' : 'local')
          break

        case 16: // Shift
          if (this.objectSelector) {
           // set translation snap to either 10cm or 5 inches based on user prefered measurement system
            this.objectSelector.setTranslationSnap(this.$store.state.AppActiveUser.measurement == 'metric' ? 0.25 : 6 * INCHES_TO_METERS) // 25 cm if metrics, 6 inches if imperial
            this.objectSelector.setRotationSnap(THREE.MathUtils.degToRad(15)) // 15 degrees steps
            this.objectSelector.setScaleSnap(0.25)
            }
          break
        case 77: // M
          self.controlMode = 'translate'
          break
        case 82: // R
          self.controlMode = 'rotate'
          break
        case 83: // S
          self.controlMode = 'scale'
          break
        case 70: // F
          this.focusOnSelectedNodes()
          break
        case 187:
        case 107: // +, =, num+
          self.control.setSize(self.control.size + 0.1)
          break

        case 189:
        case 109: // -, _, num-
          self.control.setSize(Math.max(self.control.size - 0.1, 0.1))
          break

        // case 88: // X
        //   self.control.showX = !self.control.showX
        //   break

        // case 89: // Y
        //   self.control.showY = !self.control.showY
        //   break

        // case 90: // Z
        //   self.control.showZ = !self.control.showZ
        //   break

        case 32: // Spacebar
          self.control.enabled = !self.control.enabled
          break
      }
    },
    onKeyUp(event) {
      switch (event.keyCode) {
        case 16: // Shift
          if (this.objectSelector) {
            this.objectSelector.setTranslationSnap(null)
            this.objectSelector.setRotationSnap(null)
            this.objectSelector.setScaleSnap(null)
          }
          break
      }
    },
    mouseDownHandler(transform, node) {
      this.setChildrenMaterielOpacity(transform, 0.65)
    },
    onDraggingChanged(event) {

      var dragging = event.detail.dragging
      var group = event.detail.group
      var mode = event.detail.mode
      var axis = event.detail.axis

      if (dragging) {
        this.interacting = true
        this.orbit.enabled = false
        this.distanceHelper.attach(group, this.anchorObject, mode, axis)
      }
      else {
        this.distanceHelper.detach()
        this.orbit.enabled = true
        this.interacting = false
      }
    },
    interactionStarted(event) {

      var dragging = event.detail.dragging
      var group = event.detail.group
      var originTransform = event.detail.originTransform
      var mode = event.detail.mode
      var axis = event.detail.axis

      this.interacting = true

      this.distanceHelper.attach(group, originTransform, mode, axis)

    },
    interactionUpdated(event) {
      // We need to display the relative position of the group to the anchor object in Unity coordinates
      // 1. Get the group matrix
      const groupMatrix = event.detail.matrixWorld
      const anchorMatrix = this.anchorObject.matrixWorld
      // 2. Compute the inverse matrix of the anchor object
      var inverseMatrix1 = anchorMatrix.clone().invert()
      // 3. Compute the relative position matrix
      const relativePositionMatrix = inverseMatrix1.multiply(groupMatrix)
      // 4. Convert the relative position matrix to unity coordinates
      var convertedTransform = HoverlayThreeUtils.threejsMatrixToUnity(relativePositionMatrix)

    },
    interactionEnded(event) {
      var command;

      // If no node is selected, return
      if (this.selectedNodes.length == 0) return

      // If a single node is selected, create a single command
      if (this.selectedNodes.length == 1) {

        var t = event.detail.selection[0]

        var n = this.nodes.find(node => node.pid === t.name)

        command = new Command.TransformNodeToCommand(n, HoverlayThreeUtils.threejsObjectToUnity(t))
      }
      else {

        command = new Command.NodeGroupCommand()

        event.detail.selection.forEach(t => {

          var n = this.nodes.find(node => node.pid === t.name)

          command.add(new Command.TransformNodeToCommand(n, HoverlayThreeUtils.threejsObjectToUnity(t)))
        })
      }

      this.$emit('updateNodeTransform', command)
    },
    async createHobjectsRepresentation() {
      for (const node of this.nodes) {
        var model = await this.createHobjectRepresentation(node)
        this.render()
        var box = new THREE.Box3().setFromObject(model)
        this.render()
        if (this.highest3dObject < box.max.y) this.highest3dObject = box.max.y
        this.render()
      }
      this.renderer.domElement.addEventListener('mousedown', this.onDocumentMouseDown, false)
    },
    createMeshArray(node, model) {
      var self = this
      model.traverse(function(child) {
        if (child.isMesh) {
          child.name = node.pid
          child.castShadow = true
          self.meshes.push(child)
        }
      })
    },
    onDocumentMouseDown(event) {
      // void raycast if transform control has been used (avoid event propagation)
      if (this.interacting) return

      var canvasPosition = this.renderer.domElement.getBoundingClientRect()
      var mouseX = event.clientX - canvasPosition.left
      var mouseY = event.clientY - canvasPosition.top
      var mouse = new THREE.Vector3(
        2 * (mouseX / this.renderer.domElement.clientWidth) - 1,
        1 - 2 * (mouseY / this.renderer.domElement.clientHeight),
        0.5
      )
      var raycaster = new THREE.Raycaster(
        this.currentCamera.position,
        mouse
          .unproject(this.currentCamera)
          .sub(this.currentCamera.position)
          .normalize()
      )

      var intersects = raycaster.intersectObjects(this.meshes, true)
      if (intersects.length > 0) {
        // User clicked on something
        this.on3dObjectClicked(event, intersects[0].object)
      }
    },
    setChildrenMaterielOpacity(object, opacity) {
      object.traverse(function(child) {
        if (child.isMesh) {
          child.material.transparent = true
          child.material.opacity = opacity
        }
      })
    },
    on3dObjectClicked(event, object) {

      var node = this.nodes.find(node => JSON.stringify(node.pid) == JSON.stringify(object.name));
      if (!event.shiftKey) {
        // If the object is not in selection, deselect all nodes and select the node clicked
        if (!this.objectSelector.isSelected(object.name)) {
          for (const node of this.selectedNodes) {
            this.$emit('removeNodeFromSelection', node)
          }
        }

        // If object is the only one selected, deselect it
        // if (this.objectSelector.size() == 1 && this.objectSelector.isSelected(object.name)) {
        //   this.$emit('removeNodeFromSelection', node)
        //   return
        // }
        // if (!this.objectSelector.isEmpty()) { // Deselect all nodes if there is a selection
        //   for (const node of this.selectedNodes) {
        //     this.$emit('removeNodeFromSelection', node)
        //   }
        // }
        this.$emit('addNodeToSelection', node)
      }
      else {
        // If shift key is pressed, toggle the node clicked
        if (this.objectSelector.isSelected(node.pid)) // If node is already selected, remove it from selection
          this.$emit('removeNodeFromSelection', node)
        else
          this.$emit('addNodeToSelection', node) // Otherwise add it to selection
      }
    },
    async createTrackedImageRepresentation() {
      var trackedImage = await Utils.loadImage(this.getCloudfrontUrlFromS3(this.imageAnchor.data))

      this.trackedImagePixelWidth = trackedImage.naturalWidth
      this.trackedImagePixelHeight = trackedImage.naturalHeight

      var trackedImageHeight = JSON.parse(this.imageAnchor.mark).height
      if (trackedImageHeight > this.highest3dObject) this.highest3dObject = trackedImageHeight
      var trackedImageWidth = trackedImageHeight * (this.trackedImagePixelWidth / this.trackedImagePixelHeight)
      const texture = new THREE.TextureLoader().load(trackedImage.src, this.render)
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy()
      const material = new THREE.MeshLambertMaterial({ map: texture, transparent: true, side: THREE.DoubleSide })
      material.opacity = 0.99

      this.anchorObject = new THREE.Group();
      this.anchorObject.name = "IMAGE_ANCHOR"
      // The anchor object defines the cartesian coordinate system of children object attached to the tracked image anchor. Y is orthogonal to the image. 
      this.anchorObject.rotation.set(THREE.MathUtils.degToRad(90), 0, 0)
      this.anchorObject.position.set(0, 0, 0)

      // Add the image representation to the scene
      const geometry = new THREE.PlaneGeometry(trackedImageWidth, trackedImageHeight, 32)
      const plane = new THREE.Mesh(geometry, material)
      plane.rotation.set(THREE.MathUtils.degToRad(-90), 0, 0)
      // Position the reference image plane slightly below the grid      
      plane.position.set(0, -0.001, 0)

      this.anchorObject.add(plane)

      // Create reference image size label
      if (!this.font) {
        const loader = new FontLoader()
        loader.load('/fonts/helvetiker_regular.typeface.json', f => {
          this.font = f
          this.createTrackedImageHeightLabel(trackedImageWidth, trackedImageHeight)
        })
      }
      else {
        this.createTrackedImageHeightLabel(trackedImageWidth, trackedImageHeight)
      }
      this.scene.add(this.anchorObject)
    },
    createTrackedImageHeightLabel(trackedImageWidth, trackedImageHeight) {
      const fontSize = trackedImageHeight / 20;
      const label = UnitConverter.convert(trackedImageHeight, this.$store.state.AppActiveUser.measurement)
      this.distanceTextGeometry = new TextGeometry(label, {
        font: this.font,
        size: fontSize,
        height: 0.0001,
        curveSegments: 12,
        bevelEnabled: false,
        bevelThickness: 0,
        bevelSize: 0,
        bevelOffset: 0,
        bevelSegments: 50,
      })

      this.distanceTextMaterial = new THREE.MeshBasicMaterial({ color: 0xa5a5a5 })

      this.distanceTextGeometry.computeBoundingBox()

      const centerOffset =
        -0.5 * (this.distanceTextGeometry.boundingBox.max.x - this.distanceTextGeometry.boundingBox.min.x)

      this.distanceTextMesh = new THREE.Mesh(this.distanceTextGeometry, this.distanceTextMaterial)

      this.scene.add(this.distanceTextMesh)

      this.distanceTextMesh.position.x = - (trackedImageWidth / 2) * 1.02
      this.distanceTextMesh.position.y = 0
      this.distanceTextMesh.position.z = -0.002

      this.distanceTextMesh.rotation.x = 0
      this.distanceTextMesh.rotation.z = Math.PI / 2
      this.distanceTextMesh.rotation.y = Math.PI * 2
    },
    onWindowResize() {
      this.CANVAS_HEIGHT = this.scene3d.offsetHeight
      this.CANVAS_WIDTH = this.scene3d.offsetWidth

      const aspect = this.CANVAS_WIDTH / this.CANVAS_HEIGHT

      this.cameraPersp.aspect = aspect
      this.cameraPersp.updateProjectionMatrix()

      this.cameraOrtho.left = this.cameraOrtho.bottom * aspect
      this.cameraOrtho.right = this.cameraOrtho.top * aspect
      this.cameraOrtho.updateProjectionMatrix()

      this.renderer.setSize(this.CANVAS_WIDTH, this.CANVAS_HEIGHT)

      this.render()
    },
     // gradually orbit and zoom the camera to focus on the selected nodes
    focusOnSelectedNodes() {
      if (this.selectedNodes.length == 0) return

      var target = new THREE.Vector3()
      var boundingBox = new THREE.Box3()

      this.selectedNodes.forEach(node => {
        var transform = this.transformDictionnary[node.pid]
        if (transform) {
          boundingBox.expandByObject(transform)
        }
      })

      boundingBox.getCenter(target)
      this.orbit.target = target // set the center

      // Calculate the closest camera position and rotation that would focus on the selected nodes

      var distance = boundingBox.getSize(new THREE.Vector3()).length()

      // Calculate the original distance needed to view the whole object
      distance += distance / Math.tan((this.currentCamera.fov / 2) * (Math.PI / 180.0))
      // Bring the camera closer to make the bounding box cover about half the viewport
      distance = distance / 1.8;  // Adjust this divisor to control how much of the viewport the object covers

      var direction = new THREE.Vector3()
      var position = new THREE.Vector3()
      direction = this.currentCamera.getWorldDirection(direction)
      direction.multiplyScalar(distance)

      position.copy(target).sub(direction)

      var tween = new TWEEN.Tween(this.currentCamera.position)
        .to(position, 450)
        .easing(TWEEN.Easing.Quadratic.Out)
        .start()

      tween.onUpdate(() => {
        this.currentCamera.lookAt(target)
        this.render()
      })
    },
    render() {
      if (this.threeJSVideoComponents)
        this.threeJSVideoComponents.forEach(el => {
          if (el.video.readyState == el.video.HAVE_ENOUGH_DATA) {
            el.videoImageContext.drawImage(el.video, 0, 0)
            if (el.videoTexture) el.videoTexture.needsUpdate = true
          }
        })

      //If we have selected objects, draw the selection box helper
      if (this.objectSelector && !this.objectSelector.isEmpty()) {
        this.objectSelector.refresh()
        this.distanceHelper.update()
      }
      
      //this.renderer.render(this.scene, this.currentCamera)
      this.composer.render();

      if (this.captureThumbnail == true) {
        // console.log(this.renderer.domElement)
        // var strMime = 'image/jpeg'
        // this.space.thumbnail = this.renderer.domElement.toDataURL(strMime)
        // this.onThumbnailReady()
        // this.captureThumbnail = false
      }
      //updateAnnotationOpacity()
      // this.updateScreenPosition()
    },
    reset() {
      this.setupScene()
      this.render()
    },
    getHobject(hobject_pid) {
      return JSON.parse(
        JSON.stringify(this.$store.state.hoverlay.hobjects.filter(hobject => hobject.pid == hobject_pid)[0])
      )
    },
    disposeScene() {

      cancelAnimationFrame(this.requestAnimationFrameId)
      this.renderer.setAnimationLoop(null) // pause the animation

      this.objectSelector.removeEventListener('interaction-started', event => this.interactionStarted(event))
      this.objectSelector.removeEventListener('interaction-ended', event => this.interactionEnded(event))
      this.objectSelector.removeEventListener('dragging-changed', event => this.onDraggingChanged(event))

      // Dispose of objectSelector
      this.objectSelector.dispose()

      // Dispose of distanceHelper
      this.distanceHelper.dispose()

      // Dispose text annotation
      if (this.distanceTextMesh) {
        this.distanceTextGeometry.dispose()
        this.distanceTextMaterial.dispose()
        this.scene.remove(this.distanceTextMesh)
      }

      // Others events
      this.renderer.domElement.removeEventListener('click', this.onDocumentMouseDown, false)
      this.orbit.removeEventListener('change', this.render)
      window.removeEventListener('resize', this.onWindowResize)
      window.removeEventListener('keydown', this.onKeyDown)
      window.removeEventListener('keyup', this.onKeyUp)

      DestroyUtils.destroyThreejs({ scene: this.scene, renderer: this.renderer, camera: this.camera })
      // remove reference on global attributes
      // Three js variables
      this.cameraPersp = null
      this.currentCamera = null
      this.scene = null
      this.meshes = []
      this.renderer = null
      this.outline = null
      this.objectSelector = null
      this.distanceHelper = null
      this.anchorObject = null
      this.composer = null
      this.fxaaShader = null
      this.transformDictionnary = []
      this.orbit = null
      this.CANVAS_HEIGHT = null
      this.CANVAS_WIDTH = null
      this.mannequinObject = null
      this.isFirstPersonView = false
      this.thirdPersonCoordinates = null
      this.thirdPersonOrbitTarget = null
      this.thirdPersonAngleX = null
      this.thirdPersonAngleY = null
      this.thirdPersonAngleZ = null
      //RAYCASTING
      this.raycaster = null
      this.mouse = null
      this.threeJSVideoComponents = []
      this.clock = null
      this.mixer = null
      this.mixerDictionnary = null
      return
    },
    animate() {
      const mixerUpdateDelta = this.clock.getDelta()
      if (this.mixerDictionnary)
        for (const mixer of Object.values(this.mixerDictionnary)) {
          mixer.update(mixerUpdateDelta)
        }
      this.requestAnimationFrameId = requestAnimationFrame(this.animate)
      TWEEN.update()
      this.render()
    },
    radToDeg(radians) {
      var pi = Math.PI
      return radians * (180 / pi)
    },
    SetAllObjectTransparency(transparencyValue) {
      for (const transform of Object.values(this.transformDictionnary)) {
        this.setChildrenMaterielOpacity(transform, transparencyValue)
      }
    },
    // For each node in nodes, check if they are in the objectSelector list. 
    // If some nodes are in objectSelector list but not in nodes, remove them from the objectSelector list.
    // If they are not, add them to the objectSelector list. 
    updateSelectedObjects(nodes) {
      if (!this.objectSelector.isEmpty()) {
        for (const obj of this.objectSelector.selectedObjects()) {
          var node = nodes.find(n => n.pid == obj.name)
          if (node == null) {
            this.RemoveObjectFromSelection(obj)
          }
        }
      }
      for (const node of nodes) {
        if (!this.objectSelector.isSelected(node.pid)) {
          this.AddObjectToSelection(this.transformDictionnary[node.pid])
        }
      }
      // Add selected objects to outline
      this.outline.selectedObjects = this.objectSelector.selectedObjects();
    },
    AddObjectToSelection(object) {
      if (object == null) return // not a valid object

      if (this.objectSelector.isSelected(object.name)) return // already selected

      this.objectSelector.add(object) //Add 3D object to selection

      // Unmute video if selected
      var video = document.getElementById(`video_${object.name}`)
      if (video) video.muted = false
    },
    RemoveObjectFromSelection(object) {
      if (object == null) return
      if (!this.objectSelector.isSelected(object.name)) return

      this.objectSelector.remove(object) // Remove node from selection

      // Mute video if unselected
      var video = document.getElementById(`video_${object.name}`)
      if (video) video.muted = true
    },
  },
}
</script>

<style src="@/assets/css/annotation.css"></style>
<style lang="scss">
#div-with-loading {
  height: 50px;
  width: 50px;
}
#div-with-loading > .con-vs-loading {
  background-color: rgba(255, 255, 128, 0);
}
.center-top-right-loading {
  right: 0;
  text-align: right;
  position: absolute;
  top: 0;
}

.center-top-right {
  right: 0;
  width: 300px;
  text-align: right;
  position: absolute;
  top: 0;
  padding-right: 10px;
}

.center-top-left {
  width: 50px;
  text-align: left;
  position: absolute;
  padding-left: 10px;
  top: 0;
}

.center-bottom-left {
  width: 200x;
  text-align: left;
  position: absolute;
  padding-left: 10px;
  bottom: 0;
}

.no-padding {
  padding: 0rem !important;
}
.card-no-padding > .vx-card__collapsible-content > .vx-card__body {
  padding: 0rem !important;
}

.scene3d {
  height: 550px;
  background: transparent;
}
</style>
